<template>
    <b-row>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <div
                class="d-none d-md-flex align-items-center bg-image"
                style="width: 15%"
            ></div>
            <b-card class="mb-4 w-100 h-100" v-hotkey="keymap">
                <div class="p-5">
                    <h3 class="mb-2">Datos de la sede</h3>
                    <div class="text-gray mb-5">
                        Por favor registre los datos de su sede para empezar a
                        trabajar
                    </div>
                    <b-form class="text-align-center">
                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label class="fw-medium mb-0" for="nit2"
                                    >Código
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.sede.codigo.$model"
                                    :state="validateState('codigo')"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.codigo.$anyError"
                                >
                                    ({{ $v.sede.codigo.$model.length }} /
                                    {{ $v.sede.codigo.$params.maxLength.max }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{ $v.sede.codigo.$params.maxLength.max }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label class="fw-medium mb-0" for="dv2"
                                    >Descripción
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Nombre de la sede"
                                    v-model="$v.sede.descripcion.$model"
                                    :state="validateState('descripcion')"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.descripcion.$anyError"
                                >
                                    ({{ $v.sede.descripcion.$model.length }}
                                    /
                                    {{
                                        $v.sede.descripcion.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.sede.descripcion.$params.maxLength
                                            .max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label class="fw-medium mb-0" for="razon_social"
                                    >Dirección
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <b-form-input
                                    autocomplete="null"
                                    id="direccion"
                                    type="text"
                                    placeholder="Ej: calle 27 #38-43"
                                    v-model="$v.sede.direccion.$model"
                                    :state="validateState('direccion')"
                                ></b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.direccion.$anyError"
                                >
                                    ({{ $v.sede.direccion.$model.length }} /
                                    {{
                                        $v.sede.direccion.$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.direccion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.sede.direccion.$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.direccion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>

                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label
                                    class="fw-medium mb-0"
                                    for="representante_legal"
                                    >Ciudad
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <jautocomplete
                                    id="municipio_id"
                                    :items="municipios"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    v-model="$v.sede.municipio_id.$model"
                                    :state="validateState('municipio_id')"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.municipio_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label class="fw-medium mb-0" for="bodega_id"
                                    >Bodega
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <jautocomplete
                                    id="bodega_id"
                                    :items="bodegas"
                                    item-value="id"
                                    item-text="descripcion"
                                    open-on-focus
                                    v-model="$v.sede.bodega_id.$model"
                                    :state="validateState('bodega_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.descripcion }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.bodega_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="py-3 align-items-center">
                            <b-col sm="2" class="text-left">
                                <label class="fw-medium mb-0" for="bodega_id"
                                    >Lote Inventario
                                    <small style="color: red">*</small></label
                                >
                            </b-col>
                            <b-col sm="10">
                                <jautocomplete
                                    id="lote_inventario_id"
                                    :items="lotesInventario"
                                    item-value="id"
                                    item-text="nombre"
                                    open-on-focus
                                    v-model="$v.sede.lote_inventario_id.$model"
                                    :disabled="read_only"
                                    :state="validateState('lote_inventario_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.nombre }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.lote_inventario_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="py-3 align-items-center">
                            <b-col sm="2">
                                <label for="centro_costo_id"
                                    >Centro de costo
                                    <small style="color: red">*</small>
                                </label>
                            </b-col>
                            <b-col sm="10">
                                <jautocomplete
                                    id="centro_costo_id"
                                    :items="centrosCosto"
                                    item-value="id"
                                    item-text="descripcion"
                                    open-on-focus
                                    v-model="$v.sede.centro_costo_id.$model"
                                    :disabled="read_only"
                                    :state="validateState('centro_costo_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.descripcion }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.centro_costo_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="py-3 align-items-center">
                            <b-col sm="2"></b-col>
                            <b-col sm="10" class="d-flex justify-content-end">
                                <!-- <b-button
                                    variant="success"
                                    :disabled="isLoading || read_only"
                                    size="md"
                                    v-b-popover.hover.top="
                                        'Presione ctrl+shift+s o cmd+shift+s'
                                    "
                                    @click="save"
                                >
                                    <span
                                        v-if="isLoading"
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                        <b-spinner small></b-spinner>
                                        <span class="ml-2">Guardando...</span>
                                    </span>
                                    <span v-else>Guardar</span>
                                </b-button> -->
                                <b-button
                                    variant="primary"
                                    :disabled="isLoading || read_only"
                                    size="md"
                                    v-b-popover.hover.top="
                                        'Presione ctrl+shift+s o cmd+shift+s'
                                    "
                                    class="d-flex align-items-center"
                                    @click="save"
                                >
                                    <feather
                                        type="save"
                                        size="1rem"
                                        class="mr-2 text-blue-active"
                                    />
                                    <span
                                        v-if="isLoading"
                                        class="d-sm-flex align-items-center justify-content-center"
                                    >
                                        <b-spinner small></b-spinner>
                                        <span class="ml-2">Guardando...</span>
                                    </span>
                                    <span v-else>Guardar</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import services from '@/boot/axios'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { mapGetters, mapMutations } from 'vuex'
import {
    removeListenerCommands,
    addListenerCommands,
    goToNextField
} from '@/utils/others'

const { API } = services

const defaultSede = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            direccion: '',
            empresa_id: null,
            municipio_id: null,
            bodega_id: 0,
            lote_inventario_id: 0,
            centro_costo_id: '0'
        })
    )

export default {
    name: 'CrearSedes',
    mixins: [validationMixin],
    components: { jautocomplete },
    mounted() {
        this.goToNextField('codigo')
        addListenerCommands(this)
        this.getMunicipios(this.getUltimaEmpresa.pais.id)
        this.read_only = this.$route.meta.read_only
        this.getBodegas()
        this.getCentrosCosto()
        this.getLotesInventario()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        sede: defaultSede(),
        isLoading: false,
        municipios: [],
        read_only: false,
        bodegas: [],
        lotesInventario: [],
        centrosCosto: []
    }),
    validations: {
        sede: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            municipio_id: {
                required
            },
            bodega_id: {
                required
            },
            lote_inventario_id: {
                required
            },
            centro_costo_id: {
                required
            }
        }
    },
    methods: {
        goToNextField,
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                        // console.log(this.bodegas)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getCentrosCosto() {
            API.POST({
                url: 'contabilidad/centro-costo/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.centrosCosto = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.lotesInventario = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.sede[key]
            return $dirty ? !$error : null
        },
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.sede.$invalid
            if (valid) {
                this.isLoading = true
                this.sede.empresa_id = this.getUltimaEmpresa.id
                const p_opc = this.sede.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'general/sede/crud',
                    data: {
                        p_datajson: {
                            ...this.sede
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            // this.empresa = defaultEmpresa()
                            // console.log(response.data);
                            this.setSedes([...this.getSedes, dato])
                            this.setUltimaSede(dato)
                            this.setUltimaSedeAPI(dato)
                            setTimeout(() => {
                                this.$router
                                    .push({
                                        path: '/home/general/generales/sedes'
                                    })
                                    .catch((error) => error)
                            }, 500)
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.sede.$touch()
            }
        },
        setUltimaSedeAPI(sede) {
            API.POST({
                url: 'general/selec-ultima/sede/crud',
                data: {
                    p_datajson: {
                        sede_id: sede.id,
                        usuario_id: this.getUserData.id
                    },
                    p_opc: 'INSERT',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        console.log(response.data)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        ...mapMutations('sedes', ['setSedes', 'setUltimaSede'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.save
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.save
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getSedes'])
    }
}
</script>

<style></style>
